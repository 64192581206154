h4.dish-price {
  letter-spacing: 0;
  line-height: normal;
  font-weight: bold;
}

.dish-item-wrapper {
  h4.dish-price {
    font-size: 0.9rem !important;
  }
}
