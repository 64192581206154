@import "../variables/variables";

.mdc-checkbox__background {
  width: 20px !important;
  height: 20px !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  background-color: $checkbox-checked-background-color !important;
  border: $checkbox-checked-border-color !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: $checkbox-checkmark-color !important;
  top: 2px !important;
  left: 2.15px !important;
  width: 80% !important;
}

.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  display: none !important;
}
