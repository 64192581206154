.close-icon {
  display: flex;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  align-items: center;
  justify-content: center;

  svg {
    width: 36px;
    height: 36px;
  }
}
