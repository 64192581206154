@import "../variables/variables";

.mdc-switch:enabled .mdc-switch__track::after {
  background: $slide-checked-background-color !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #fff !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  fill: $slide-checkmark-color !important;
}
