.mf-toggle-wrapper {
  width: 85%;
  max-width: 412px;
}

.mf-toggle-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  border: 4px solid #00b892;
  border-radius: 50px;
  background: #00b892;
  font-weight: bold;
  color: #343434;
  min-height: 50px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.mf-toggle-container::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0%;
  border-radius: 50px;
  background: #c2fef2;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
}

.mf-toggle-container div {
  padding: 6px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}

.mf-toggle-container.active::before {
  left: 50%;
}
