.popup-top-border-wrapper {
  position: absolute;
  top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  span {
    background: #ccc;
    width: 3rem;
    height: 0.4rem;
    border-radius: 2rem;
  }
}
