.allergens-alert-backdrop {
  background: rgba(0, 0, 0, 0);
}

.allergens-alert-popup {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    background-color: transparent;
    box-shadow: none;
    padding: 20px;
  }

  close-green-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .allergens-alert-wrapper {
    background: linear-gradient(#c2fef1, #82ecd5);
    padding: 1rem 0.8rem;
    border-radius: 16px;

    p {
      color: #343434;
      text-align: left;
      font-style: italic;
      font-weight: 600;
      line-height: normal;
      font-size: 13px;
    }
  }
}
