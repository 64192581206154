.three-dot-menu-container.mat-mdc-menu-panel {
  background: transparent;
  box-shadow: none;
  padding: 0.2rem;
  padding-top: 2px;

  .menu-wrapper {
    background-color: #fff;
    border-radius: 0.6rem !important;
    padding: 0.9rem 1rem !important;
    position: relative;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);

    &::before {
      content: "";
      width: 14px;
      height: 14px;
      position: absolute;
      top: -6px;
      right: 14px;
      border-bottom: none;
      border-right: none;
      background-color: white;
      transform: rotate(45deg);
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);
    }

    &::after {
      content: "";
      width: 34px;
      height: 14px;
      position: absolute;
      top: 0px;
      right: 9px;
      background-color: #fff;
    }
  }
}

.menu-button,
.menu-link {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  img,
  svg {
    width: auto;
    height: 22px;
    object-fit: contain;
    margin-top: -2px;
  }

  adjust-icon {
    position: relative;

    &.preferences-counter::after {
      @extend %preferencesCounter;
      width: 16px;
      right: -2px;
    }

    &.elliptic::after {
      @extend %preferencesCounter;
      right: -4px;
      width: 18px;
    }
  }

  .btn-text {
    margin-left: 0.3rem;
    letter-spacing: 0;
    font-size: 1rem;
  }
}

.menu-button .btn-text {
  font-weight: bold;
}

%preferencesCounter {
  position: absolute;
  content: attr(aria-valuetext);
  color: #fff;
  background-color: var(--primary-color);
  top: -7px;
  height: 17px;
  min-width: 17px;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 800;
  letter-spacing: 0;
}
