.primary-btn,
.secondary-btn {
  color: #fff !important;
  letter-spacing: 0 !important;
  border-radius: 0.7rem !important;
  font-weight: 600;
}

.secondary-btn {
  background: var(--secondary-color) !important;
}

.primary-btn {
  background: var(--primary-color) !important;
}

.popup-top-border-wrapper {
  position: absolute;
  top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.popup-top-border-wrapper span {
  background: #ccc;
  width: 3rem;
  height: 0.4rem;
  border-radius: 2rem;
}

.mdc-checkbox__background {
  width: 20px !important;
  height: 20px !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  background-color: #1d1c1a !important;
  border: #1d1c1a !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff !important;
  top: 2px !important;
  left: 2.15px !important;
  width: 80% !important;
}

.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  display: none !important;
}

.mdc-switch:enabled .mdc-switch__track::after {
  background: #1d1c1a !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #fff !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  fill: #1d1c1a !important;
}

.three-dot-menu-container.mat-mdc-menu-panel {
  background: transparent;
  box-shadow: none;
  padding: 0.2rem;
  padding-top: 2px;
}
.three-dot-menu-container.mat-mdc-menu-panel .menu-wrapper {
  background-color: #fff;
  border-radius: 0.6rem !important;
  padding: 0.9rem 1rem !important;
  position: relative;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);
}
.three-dot-menu-container.mat-mdc-menu-panel .menu-wrapper::before {
  content: "";
  width: 14px;
  height: 14px;
  position: absolute;
  top: -6px;
  right: 14px;
  border-bottom: none;
  border-right: none;
  background-color: white;
  transform: rotate(45deg);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);
}
.three-dot-menu-container.mat-mdc-menu-panel .menu-wrapper::after {
  content: "";
  width: 34px;
  height: 14px;
  position: absolute;
  top: 0px;
  right: 9px;
  background-color: #fff;
}

.menu-button,
.menu-link {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}
.menu-button img,
.menu-button svg,
.menu-link img,
.menu-link svg {
  width: auto;
  height: 22px;
  object-fit: contain;
  margin-top: -2px;
}
.menu-button adjust-icon,
.menu-link adjust-icon {
  position: relative;
}
.menu-button adjust-icon.preferences-counter::after,
.menu-link adjust-icon.preferences-counter::after {
  width: 16px;
  right: -2px;
}
.menu-button adjust-icon.elliptic::after,
.menu-link adjust-icon.elliptic::after {
  right: -4px;
  width: 18px;
}
.menu-button .btn-text,
.menu-link .btn-text {
  margin-left: 0.3rem;
  letter-spacing: 0;
  font-size: 1rem;
}

.menu-button .btn-text {
  font-weight: bold;
}

.menu-button adjust-icon.preferences-counter::after,
.menu-link adjust-icon.preferences-counter::after, .menu-button adjust-icon.elliptic::after,
.menu-link adjust-icon.elliptic::after {
  position: absolute;
  content: attr(aria-valuetext);
  color: #fff;
  background-color: var(--primary-color);
  top: -7px;
  height: 17px;
  min-width: 17px;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 800;
  letter-spacing: 0;
}

.dish-popup-container {
  background-color: #fff;
  border-radius: 1.1rem 1.1rem 0 0;
  box-shadow: -2px 0px 4px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-x: hidden;
  height: 100%;
}
.dish-popup-container h2,
.dish-popup-container h3,
.dish-popup-container h4 {
  letter-spacing: 0;
  line-height: normal;
  font-weight: bold;
}
.dish-popup-container p {
  line-height: normal;
  letter-spacing: 0;
}
.dish-popup-container .labels-image {
  min-width: 32px;
  width: 32px;
  height: 32px;
}
.dish-popup-container .labels-image.additional {
  min-width: 20px;
  width: auto;
  height: 24px;
  object-fit: contain;
  margin-left: 3px;
  margin-top: -5px;
}
.dish-popup-container .menu-item-image {
  object-fit: cover;
  border-radius: 12px;
  min-width: 130px;
  width: 130px;
  height: 130px;
}

.disclaimer-text {
  display: flex;
  justify-content: center;
  width: 100vw;
}
.disclaimer-text div.tooltip-container {
  width: 300px;
  font-size: 0.8rem;
  letter-spacing: 0;
  line-height: 20px;
  color: #1d1c1a;
  padding: 1rem;
  background: #fff;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}
.disclaimer-text div.tooltip-container .link {
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: var(--primary-color);
}

h4.dish-price {
  letter-spacing: 0;
  line-height: normal;
  font-weight: bold;
}

.dish-item-wrapper h4.dish-price {
  font-size: 0.9rem !important;
}

.mf-toggle-wrapper {
  width: 85%;
  max-width: 412px;
}

.mf-toggle-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  border: 4px solid #00b892;
  border-radius: 50px;
  background: #00b892;
  font-weight: bold;
  color: #343434;
  min-height: 50px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.mf-toggle-container::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0%;
  border-radius: 50px;
  background: #c2fef2;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
}

.mf-toggle-container div {
  padding: 6px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}

.mf-toggle-container.active::before {
  left: 50%;
}

.survey-banner-wrapper {
  background-color: #82ecd5;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  padding-left: 0.5rem;
  align-items: center;
}
.survey-banner-wrapper p {
  font-size: 14px;
  font-style: italic;
  margin: 0;
  padding: 0;
}

.survey-close-btn {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  padding: 0;
}

.survey-go-btn {
  background-color: #00805a;
  font-size: 1.2rem;
  padding: 0.6rem 1rem;
  font-weight: 500;
  color: #fff;
  font-style: italic;
  border-radius: 0.9rem;
  border: none;
}

.survey-header-wrapper {
  background-color: #82ecd5;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  min-height: 13rem;
}
.survey-header-wrapper menufy-web-close-survey {
  align-self: flex-end;
  margin-bottom: 1rem;
  margin-right: -0.5rem;
}
.survey-header-wrapper h1 {
  font-size: 1.3rem;
  font-weight: bold;
  align-self: center;
}
.survey-header-wrapper h1 span {
  color: #009576 !important;
}
.survey-header-wrapper p {
  font-size: 1;
  align-self: flex-start;
}

.survey-stepper-wrapper {
  padding: 1rem;
  margin-top: 2rem;
}
.survey-stepper-wrapper .question-title {
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0;
}
.survey-stepper-wrapper .next-btn {
  color: #009576 !important;
  background-color: #82ecd5 !important;
}
.survey-stepper-wrapper .back-btn {
  background-color: #c2fef4 !important;
  color: #4eb6a2 !important;
}

.survey-stepper-wrapper .next-btn, .survey-stepper-wrapper .back-btn {
  border-radius: 0.5rem !important;
}
.survey-stepper-wrapper .next-btn mat-icon, .survey-stepper-wrapper .back-btn mat-icon {
  font-size: 30px;
  margin: 0px;
  padding: 0px;
  font-weight: bold;
}
.survey-stepper-wrapper .next-btn span, .survey-stepper-wrapper .back-btn span {
  font-weight: bold;
  letter-spacing: 0;
}

.allergens-alert-backdrop {
  background: rgba(0, 0, 0, 0);
}

.allergens-alert-popup .mat-mdc-dialog-surface.mdc-dialog__surface {
  background-color: transparent;
  box-shadow: none;
  padding: 20px;
}
.allergens-alert-popup close-green-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.allergens-alert-popup .allergens-alert-wrapper {
  background: linear-gradient(#c2fef1, #82ecd5);
  padding: 1rem 0.8rem;
  border-radius: 16px;
}
.allergens-alert-popup .allergens-alert-wrapper p {
  color: #343434;
  text-align: left;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  font-size: 13px;
}

.text-black {
  color: #1d1c1a;
}

.text-gray-2 {
  color: #393836;
}

.text-gray-3 {
  color: #625e5d;
}

.text-gray {
  color: #91908c;
}

.primary-text {
  color: var(--primary-color) !important;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dark-tone-text {
  color: var(--dark-tone) !important;
}

.logo-text {
  text-align: center;
  font-size: 2rem !important;
  margin-top: 16px !important;
  font-weight: bold !important;
  color: #00b992;
  letter-spacing: 0;
}

.created-by-text {
  text-align: center;
  padding: 4px 0px 20px 0px;
}
.created-by-text a {
  font-size: 14px;
  font-weight: 500;
  color: #1d1c1a;
  text-decoration: none;
}
.created-by-text .eateasy-text {
  color: #00b992;
  font-weight: 700;
}

.accent-bg {
  background: rgba(var(--accent-color-hex)) !important;
}

.close-icon {
  display: flex;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  align-items: center;
  justify-content: center;
}
.close-icon svg {
  width: 36px;
  height: 36px;
}

.line-separator {
  margin: 0 auto;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
}