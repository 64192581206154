@import "../variables/variables";

.text-black {
  color: $text-gray-1;
}

.text-gray-2 {
  color: $text-gray-2;
}

.text-gray-3 {
  color: $text-gray-3;
}

.text-gray {
  color: $text-gray-4;
}

.primary-text {
  color: $primary-color !important;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dark-tone-text {
  color: $dark-tone !important;
}

.logo-text {
  text-align: center;
  font-size: 2rem !important;
  margin-top: 16px !important;
  font-weight: bold !important;
  color: #00b992;
  letter-spacing: 0;
}

.created-by-text {
  text-align: center;
  padding: 4px 0px 20px 0px;

  a {
    font-size: 14px;
    font-weight: 500;
    color: $text-gray-1;
    text-decoration: none;
  }

  .eateasy-text {
    color: #00b992;
    font-weight: 700;
  }
}
