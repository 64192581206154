$colors: (
  "primary": var(--primary-color),
  "secondary": var(--secondary-color),
  "accent": rgba(var(--accent-color)),
  "dark-tone": var(--dark-tone),
  "primary-5": rgba(var(--primary-color-hex)),
  "secondary-5": rgba(var(--secondary-color-hex)),
  "accent-5": rgba(var(--accent-color-hex)),
  "dark-tone-5": rgba(var(--dark-tone-hex)),
);

@function get-color($color) {
  @if map-has-key($colors, $color) {
    @return map-get($colors, $color);
  } @else {
    @warn "Color `#{$color}` not found in the color map.";
    @return null;
  }
}

$text-gray-1: #1d1c1a;
$text-gray-2: #393836;
$text-gray-3: #625e5d;
$text-gray-4: #91908c;
$primary-color: get-color("primary");
$primary-color-5: get-color("primary-5");
$secondary-color: get-color("secondary");
$secondary-color-5: get-color("secondary-5");
$accent-color: get-color("accent");
$accent-color-5: get-color("accent-5");
$dark-tone: get-color("dark-tone");
$dark-tone-5: get-color("dark-tone-5");

// Checkbox color
$checkbox-checked-background-color: $text-gray-1;
$checkbox-checked-border-color: $text-gray-1;
$checkbox-checkmark-color: #fff;

// Slide Toggle Colors
$slide-checked-background-color: $text-gray-1;
$slide-checkmark-color: $text-gray-1;
