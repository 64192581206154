@import "../variables/variables";

.survey-banner-wrapper {
  background-color: #82ecd5;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  padding-left: 0.5rem;
  align-items: center;

  p {
    font-size: 14px;
    font-style: italic;
    margin: 0;
    padding: 0;
  }
}

.survey-close-btn {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  padding: 0;
}

.survey-go-btn {
  background-color: #00805a;
  font-size: 1.2rem;
  padding: 0.6rem 1rem;
  font-weight: 500;
  color: #fff;
  font-style: italic;
  border-radius: 0.9rem;
  border: none;
}

.survey-header-wrapper {
  background-color: #82ecd5;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  min-height: 13rem;

  menufy-web-close-survey {
    align-self: flex-end;
    margin-bottom: 1rem;
    margin-right: -0.5rem;
  }

  h1 {
    font-size: 1.3rem;
    font-weight: bold;
    align-self: center;

    span {
      color: #009576 !important;
    }
  }

  p {
    font-size: 1;
    align-self: flex-start;
  }
}

.survey-stepper-wrapper {
  padding: 1rem;
  margin-top: 2rem;

  .question-title {
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0;
  }

  .next-btn {
    @extend %survey-btn;
    color: #009576 !important;
    background-color: #82ecd5 !important;
  }

  .back-btn {
    @extend %survey-btn;
    background-color: #c2fef4 !important;
    color: #4eb6a2 !important;
  }
}

%survey-btn {
  border-radius: 0.5rem !important;

  mat-icon {
    font-size: 30px;
    margin: 0px;
    padding: 0px;
    font-weight: bold;
  }

  span {
    font-weight: bold;
    letter-spacing: 0;
  }
}
