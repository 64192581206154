@import "../variables/variables";

.primary-btn,
.secondary-btn {
  color: #fff !important;
  letter-spacing: 0 !important;
  border-radius: 0.7rem !important;
  font-weight: 600;
}

.secondary-btn {
  background: $secondary-color !important;
}

.primary-btn {
  background: $primary-color !important;
}
