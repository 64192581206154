@import "../variables/variables";

.dish-popup-container {
  background-color: #fff;
  border-radius: 1.1rem 1.1rem 0 0;
  box-shadow: -2px 0px 4px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-x: hidden;
  height: 100%;

  h2,
  h3,
  h4 {
    letter-spacing: 0;
    line-height: normal;
    font-weight: bold;
  }

  p {
    line-height: normal;
    letter-spacing: 0;
  }

  .labels-image {
    min-width: 32px;
    width: 32px;
    height: 32px;

    &.additional {
      min-width: 20px;
      width: auto;
      height: 24px;
      object-fit: contain;
      margin-left: 3px;
      margin-top: -5px;
    }
  }

  .menu-item-image {
    object-fit: cover;
    border-radius: 12px;
    min-width: 130px;
    width: 130px;
    height: 130px;
  }
}

.disclaimer-text {
  display: flex;
  justify-content: center;
  width: 100vw;

  div.tooltip-container {
    width: 300px;
    font-size: 0.8rem;
    letter-spacing: 0;
    line-height: 20px;
    color: $text-gray-1;
    padding: 1rem;
    background: #fff;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;

    .link {
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;
      color: var(--primary-color);
    }
  }
}
